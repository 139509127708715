import React from 'react';
import { Button, Select } from 'antd';

import './index.less';

const IconFont = Loader.loadBaseComponent('IconFont');
const ZipUpload = Loader.loadBusinessComponent('UploadComponents', 'ZipUpload');
const Option = Select.Option;

class InfoFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.disTime = null;
  }

  componentWillUnmount() {
    clearTimeout(this.disTime);
  }

  handleChange = value => {
    const { search, type } = this.props;
    let option = { page: 1 };
    if (type === 'people') {
      option.importStatus = value;
    }
    if (type === 'vehicle') {
      option.status = value;
    }
    search && search(option);
  };

  deletePeople = () => {
    const { deletePeople } = this.props;
    deletePeople && deletePeople();
    this.setState({
      disabled: true
    });
    this.disTime = setTimeout(() => {
      this.setState({
        disabled: false
      });
    }, 500);
  };

  handleAssociationChange = value => {
    const { search, type } = this.props;
    let option = {
      page: 1,
      residentialArea: undefined, // 是否在本小区居住(0:不在,1在)
      associationStatus: undefined // 是否关联社区人员成功(0:不成功,1:成功)
    };
    switch (value) {
      case -1:
        break;
      case 1:
        option.residentialArea = 0;
        break;
      case 2:
        option.residentialArea = 1;
        option.associationStatus = 1;
        break;
      case 3:
        option.residentialArea = 1;
        option.associationStatus = 0;
        break;
      default:
        return;
    }
    search && search(option);
  };

  downloadZip = () => {
    const { type = 'people' } = this.props;
    const a = document.createElement('a');
    let download = '',
      href = '';
    if (type === 'people') {
      download = '常住人口信息采集模板.zip';
      href = '/resource/file/templete.zip';
    }
    if (type === 'vehicle') {
      download = '小区车辆导入信息模板.zip';
      href = '/resource/file/templeteVehicle.zip';
    }
    a.setAttribute('download', download);
    a.setAttribute('href', href);
    a.click();
  };

  render() {
    let { searchData = {}, choseList = [], uploadZip, type, optionList, uploadType = 'lyy', associationOption } = this.props;
    const { residentialArea, associationStatus } = searchData;
    const status = type === 'people' ? searchData.importStatus : searchData.status;
    let ssociation = -1;
    if (!residentialArea && !associationStatus) {
      ssociation = -1;
    }
    if (residentialArea === 0) {
      ssociation = 1;
    }
    if (residentialArea === 1 && associationStatus === 1) {
      ssociation = 2;
    }
    if (residentialArea === 1 && associationStatus === 0) {
      ssociation = 3;
    }
    return (
      <div className="info-filter">
        <div className="filter-left">
          <ZipUpload uploadZip={uploadZip} uploadType={uploadType} />
        </div>
        <div className="filter-right">
          <div className="tm-download" onClick={this.downloadZip}>
            <IconFont type={'icon-S_Edit_Download'} theme="outlined" /> 文件模板下载
          </div>
          <Button disabled={choseList.length === 0 || this.state.disabled} onClick={this.deletePeople}>
            <IconFont type={'icon-S_Edit_Delete'} theme="outlined" />
            批量删除
          </Button>
          {type === 'vehicle' && (
            <Select
              className="info-association-select"
              dropdownClassName="info-association-drop"
              defaultValue={-1}
              value={ssociation}
              style={{ width: 160 }}
              onChange={this.handleAssociationChange}>
              {associationOption.map((item, index) => (
                <Option value={item.value}>
                  {item.icon && <IconFont type={item.icon} theme="outlined" className={`icon-filter icon-${index}`} />}
                  {item.color && <i className={`bold ${item.color}`} />}
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
          <Select dropdownClassName="info-filter-drop" defaultValue={-1} value={status} style={{ width: 160 }} onChange={this.handleChange}>
            {optionList.map(item => (
              <Option value={item.value}>
                {item.icon && <IconFont type={item.icon} theme="outlined" className="icon-filter" />}
                {item.color && <i className={`bold ${item.color}`} />}
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}
export default InfoFilter;
