import React, { Component } from 'react';
import './index.less';

const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent');

class InfoConfirm extends Component {
  render() {
    const { type, data = {}, messType } = this.props;
    // const { successCount = 0, failCount = 0, picWithoutFeatureCount, noPicCount, wrongInfoCount = 0, otherCount, totalCount = 0 } = data;
    return (
      <ConfirmComponent
        {...this.props}
        className="info-confim-modal"
        title={type === 0 ? '删除确认' : '导入统计'}
        width={type === 0 ? 320 : 380}
        icon={type === 0 ? 'icon-S_Edit_Delete' : ''}
        children={
          // type === 0 ? (
            <div>确定将当前选中{messType === 'people' ? '常住人口' : '车辆'}从系统中移除？</div>
          // ) : (
          //   <div className="community-upload-modal">
          //     <div className="title">
          //       <span className="value">导入成功：</span>
          //       <div className="info">{messType === 'people' ? `${successCount}人` : `${totalCount - wrongInfoCount}车`}</div>
          //     </div>
          //     <div className="title">
          //       <span className="value">导入失败：</span>
          //       <div className="info">{messType === 'people' ? `${failCount}人` : `${wrongInfoCount}车`}</div>
          //     </div>
          //     {messType === 'people' && (
          //       <div className="title">
          //         <span className="value">失败原因：</span>
          //         <div className="value-box">
          //           <p className="box-title">
          //             <span className="box-span">照片无特征值 </span> {picWithoutFeatureCount || 0}人
          //           </p>
          //           <p className="box-title">
          //             <span className="box-span">暂无照片 </span> {noPicCount || 0}人
          //           </p>
          //           <p className="box-title">
          //             <span className="box-span">信息填写错误 </span> {wrongInfoCount || 0}人
          //           </p>
          //           <p className="box-title">
          //             <span className="box-span">其他 </span> {otherCount || 0}人
          //           </p>
          //         </div>
          //       </div>
          //     )}
          //   </div>
          // )
        }
      />
    );
  }
}

export default InfoConfirm;
